import React from 'react';
import Layout from '../../layouts/index';
import { graphql, StaticQuery } from 'gatsby';
const Contact = props => (
  <Layout bodyClass="page-team">
    <div className="container pt-4 pt-md-10">
      <div className="row justify-content-start">
        <div className="col-12 col-md-8">
          <div className="team">
            <h1 className="title">Contact Us</h1>
            <div className="content">
              <p> {props.data.site.siteMetadata.contact.address1}</p>
              <p> {props.data.site.siteMetadata.contact.address2}</p>
              <p>
                Tel no : {props.data.site.siteMetadata.contact.phone}/
                {props.data.site.siteMetadata.contact.phone2}
              </p>
              <p>Email : {props.data.site.siteMetadata.contact.email}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              email
              phone
              phone2
              address1
              address2
            }
          }
        }
      }
    `}
    render={data => <Contact data={data} />}
  />
);
